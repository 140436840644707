<template>
  <div id="app">
  </div>
</template>

<script>
export default {
  name: 'app',
  components: {
  },
  data() {
    return {
      errorMessage: '',
      scanned: '',
      show: true,
    };
  },
  methods: {
  },
};
</script>

<style></style>
